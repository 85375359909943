<template>
  <main class="other-auth">
    <div class="spinner">
      <i class="fa-solid fa-circle-notch fa-spin fa-3x"></i>
    </div>
  </main>
</template>

<script>
import axios from "axios";
export default {
  name: "Callback",
  mounted() {
    let platform = this.$route.params.platform;
    let code = this.$route.query.code;
    if (!code || !platform) return this.$router.push("/");

    if (platform === "discord") {
      let data = {
        params: {
          code,
        },
      };
      if (this.$store.state.token) data.headers = { Authorization: `Bearer ${this.$store.state.token}` } ;
      axios.get(this.$api("/user/discord/callback"), 
          data
        ).then((response) => {
        console.log("discord login response", response);
        console.log(this.$store.state.user);
        if (this.$store.state.user) {
          this.$store.commit("setUser", response.data.user);
          this.$notify({
            type: "success",
            text: `Successfully connected ${platform} to your account.`,
          });
          this.$router.push("/settings/connections");
        } else {
          this.$store.commit("removeLatestRedirectPath");
          this.$store.commit("setToken", response.data.token);
          this.$store.commit("setUser", response.data.user);
          this.$router.push("/profile");
        }
      }).catch(err => {
        this.$notify({
          type: "error",
          text: err.response.data,
        });
        console.log(this.$store.state.user);
        if (this.$store.state.user) {
          this.$router.push("/");
        } else {
          this.$router.push("/login");
        }
        this.$router.push("/profile")
        console.log("discord login error", err);
      });
    }
  }
}
</script>